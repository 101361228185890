<template>
  <b-modal header-class="ion-bg-modal-header" size="lg"
           centered scrollable no-close-on-backdrop
           v-model="visible">

    <template #modal-header>
      <div class="fw-bold">{{ $t('output.outputTitle') }} {{ $t('common.setting') }}</div>
    </template>

    <div v-if="isLoading" class="text-center">
      <b-spinner variant="primary" style="width: 2.5rem; height: 2.5rem;" />
      <div>Loading...</div>
    </div>

    <div v-else class="row">
      <div class="col-xl-12">
        <div class="row mb-3">
          <div class="col-xl-12">
            <label class="ion-modal-form-group-title pb-2" for="output-update-hq">{{ $t('common.headquarters') }}</label>
            <multiselect id="output-update-hq"
                         v-model="form.hqId"
                         label="text" track-by="value"
                         :options="hqOptions"
                         :placeholder="$t('alert.selectHq')"
                         selectLabel=""
                         selectedLabel=""
                         @select="onSelectHq"
                         deselectLabel=""
                         :searchable="false" :allow-empty="false"
                         :disabled="$_app.user.roles[0] !== 'ADMIN'"
                         :class="{ 'is-invalid': submitted && $v.form.hqId.$error }" />
            <div v-if="submitted && $v.form.hqId.$error" class="invalid-feedback">
              <span v-if="!$v.form.hqId.required">{{ $t('validation.hqIsRequired') }}</span>
            </div>
          </div>
        </div>
        <div class="ion-modal-form-group-title pt-3 pb-2">{{ $t('output.cbList') }}</div>

        <div class="row mb-3">
          <label class="form-label fw-bold">{{ $t('output.prevConfigLimit') }}</label>
          <div v-if="!form.hqId" class="text-secondary ms-1">{{ $t('alert.selectHq') }}</div>
          <div v-else-if="hasSettingValue">
            <span v-if="has90" class="ms-1 me-1"><span class="fw-bold">{{ $t('common.fast') }} 90 kW :</span> {{ priviousValue.val90 }} kW</span>
            <span v-if="has120" class="ms-1 me-1"><span class="fw-bold">{{ $t('common.fast') }} 120 kW :</span> {{ priviousValue.val120 }} kW</span>
            <span v-if="has180" class="ms-1 me-1"><span class="fw-bold">{{ $t('common.fast') }} 180 kW :</span> {{ priviousValue.val180 }}kW</span>
          </div>
          <div v-else class="ms-1">{{ $t('common.no') }}</div>
        </div>

        <hr/>

        <!-- // 90 kW -->
        <div class="row mb-3">
          <div class="col-xl-4 fw-bold" :class="has90 ? 'text-black' : 'text-black-50'">{{ $t('common.fast') }} 90 kW</div>
          <div class="col-xl-6">
            <range-slider class="slider w-100"
                          min="0" max="90" step="1"
                          :disabled="!has90"
                          v-model="form.val90" />
          </div>
          <div class="col-xl-2 fw-bold text-end" :class="has90 ? 'text-black' : 'text-black-50'">{{ form.val90 ?? '-' }} kW</div>
        </div>
        <!-- // 120 kW -->
        <div class="row mb-3">
          <div class="col-xl-4 fw-bold" :class="has120 ? 'text-black' : 'text-black-50'">{{ $t('common.fast') }} 120 kW</div>
          <div class="col-xl-6">
            <range-slider class="slider w-100"
                          min="0" max="90" step="1"
                          :disabled="!has120"
                          v-model="form.val120" />
          </div>
          <div class="col-xl-2 fw-bold text-end" :class="has120 ? 'text-black' : 'text-black-50'">{{ form.val120 ?? '-' }} kW</div>
        </div>
        <!-- // 180 kW -->
        <div class="row mb-3">
          <div class="col-xl-4 fw-bold" :class="has180 ? 'text-black' : 'text-black-50'">{{ $t('common.fast') }} 180 kW</div>
          <div class="col-xl-6">
            <range-slider class="slider w-100"
                          min="0" max="180" step="1"
                          :disabled="!has180"
                          v-model="form.val180" />
          </div>
          <div class="col-xl-2 fw-bold text-end" :class="has180 ? 'text-black' : 'text-black-50'">{{ form.val180 ?? '-' }} kW</div>
        </div>

        <div class="row mb-3">
          <div>* {{ $t('output.message1') }}</div>
          <div>{{ $t('output.message2') }}</div>
        </div>
      </div>
    </div>

    <template #modal-footer>
      <div class="w-100 text-end">
        <b-button variant="secondary" class="w-xs p-1 me-3" @click="close">{{ $t('btn.close') }}</b-button>
        <b-button variant="success" class="w-xs p-1" @click="save">{{ $t('btn.save') }}</b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
import { http } from '@/auth-api';
import { required } from 'vuelidate/lib/validators';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect';
import RangeSlider from 'vue-range-slider'
import 'vue-range-slider/dist/vue-range-slider.css'

export default {
  components: {
    Multiselect, RangeSlider
  },
  data() {
    return {
      visible: false,
      isLoading: false,
      submitted: false,
      hqOptions: [],
      form: {
        hqId: null,
        val90: null,
        val120: null,
        val180: null
      },
      has90: false,
      has120: false,
      has180: false,
      priviousValue: {
        val90: null,
        val120: null,
        val180: null,
      },
      hasSettingValue: false,
    }
  },
  validations: {
    form: {
      hqId: { required }
    }
  },
  methods: {
    show() {
      const vm = this;

      vm.getHqOptions();
      vm.visible = true;
    },
    getHqOptions() {
      const vm = this;

      const _url = '/onmapi/view/Hq/listAll';

      vm.isLoading = true;

      http.get(_url, {}).then(res => {
        vm.hqOptions = [];

        res.data.items.forEach(item => {
          vm.hqOptions.push({text: item.hqName, value: item.id, configLimits: item.configLimits });
        });
      }).then(() => {
        if (this.$_app.user.roles[0] === 'HQ') {
          vm.form.hqId = vm.hqOptions.find(ele => ele.value === this.$_app.user.hqId);
        }
        vm.isLoading = false;
      }).catch(err => {
        console.error('Headquarters List Data Error :: ', err)
      });
    },
    onSelectHq(e) {
      const vm = this;

      vm.has90 = vm.has120 = vm.has180 = false;
      vm.retrieveOutputList(e.text);

      vm.hasSettingValue = e.configLimits.hqId != null;
      vm.form = {
        ...vm.form,
        val90: vm.hasSettingValue ? e.configLimits.limit90 : '-',
        val120: vm.hasSettingValue ? e.configLimits.limit120 : '-',
        val180: vm.hasSettingValue ? e.configLimits.limit180 : '-',
      }
      vm.priviousValue = {
        val90: vm.hasSettingValue ? e.configLimits.limit90 : null,
        val120: vm.hasSettingValue ? e.configLimits.limit120 : null,
        val180: vm.hasSettingValue ? e.configLimits.limit180 : null,
      }

    },
    async retrieveOutputList(hqName) {
      const vm = this;
      const _url = '/onmapi/view/ChargePoint/list';

      vm.isLoading = true;
      await http.post(_url, {
        page: 1,
        limit: 50,
        searchConditions: [
          { 'searchKey': 'chargerType', 'searchValue': 'FAST' },
          { 'searchKey': 'hqName', 'searchValue': hqName }
        ],
        // sortCondition: { sortDirection: vm.sortDirection }
      }).then(res => {
        const hqCpKist = res.data.items;
        hqCpKist.forEach(chargeBox => {
          if (chargeBox.maxKw === 90) {
            vm.has90 = true;
          } else if (chargeBox.maxKw === 120) {
            vm.has120 = true;
          } else if (chargeBox.maxKw === 180) {
            vm.has180 = true;
          }
        });
        vm.isLoading = false;
      }).catch(err => {
        console.error('Charge Box List Error :: ', err)
      });
    },
    close() {
      const vm = this;

      this.$emit('callback');
      vm.resetForm();
      vm.visible = false;
    },
    resetForm() {
      const vm = this;

      vm.hqOptions = [];

      vm.form = {
        hqId: null,
        val90: null,
        val120: null,
        val180: null
      };

      vm.has90 = vm.has120 = vm.has180 = false;
      vm.hasSettingValue = false;
      vm.priviousValue = {
        val90: null,
        val120: null,
        vaal180: null,
      };

      vm.$nextTick(() => {
        this.$v.$reset();
      });
    },
    save() {
      const vm = this;
      vm.submitted = true;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        Swal.fire({
          title: i18n.t('alert.confirmOutput'),
          showCancelButton: true,
          confirmButtonText: i18n.t('btn.ok'),
          cancelButtonText: i18n.t('btn.cancel')
        }).then((result) => {
          if (result.isConfirmed) {
            vm.saveItems();
          }
        });
      }
    },
    saveItems() {
      const vm = this;
      const _url = '/onmapi/view/ChargePoint/setChargingProfileByHqId';

      let _form = {
        'hqId': vm.form.hqId.value,
        'connectorId': 0,
        'limit90': vm.form.val90,
        'limit120': vm.form.val120,
        'limit180': vm.form.val180,
        'unit': 'W'
      }

      http.post(_url, _form).then(res => {
        if (res) {
          Swal.fire({
            icon: 'info',
            title: i18n.t('alert.changed'),
            confirmButtonText: i18n.t('btn.close')
          });
          vm.close();
        }
      }).catch(err => {
        console.error('Charge Box Create Error :: ', err)

        Swal.fire({
          icon: 'error',
          title: i18n.t('alert.error'),
          confirmButtonText: i18n.t('btn.close')
        });
      });
    },
  }
}
</script>

<style lang="scss">
::v-deep {
  .range-slider {
    width: 100%;
  }

  .range-slider-fill {
    background-color: #038EDCFF;
  }
}
</style>
